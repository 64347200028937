import React from "react"
import Layout from "../components/layout/Layout.js"
import { Column100 } from "../components/column/Column.js"
import { BlogRow } from "../components/row/Row.js"
import { TextMedium } from "../components/text/Text"
import { BlogTitle } from "../components/title/Title"
import { Section } from "../components/section/Section.js"
import Seo from '../components/seo'


function GetEstimateSuccess() {
  return (
  <Layout>
  <Seo title="Form Sent Successfully" />
    <Section>
      <BlogRow isCentered>
        <Column100>
          <BlogTitle isPrimary title="Thanks for requesting a price estimate!" />
          <TextMedium
            textMedium="We will get in touch within 48 hours, either for additional details
          or with an offer."
          />
          <TextMedium
            textMedium="If you have questions meanwhile or want to add something to your previous message, 
          you can always email us at contact@schoolofcontent.net."
          />
          <TextMedium textMedium="Thanks and you'll hear from us soon!" />
        </Column100>
      </BlogRow>
    </Section>
</Layout>
)}

export default GetEstimateSuccess
